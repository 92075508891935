<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import API from "@/plugins/axios-method";

export default {
  page: {
    title: "Paramètre de l'application",
    meta: [{ name: "description", content: 'Application Dashboard' }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      url: '/app_params',
      id: null,
      registrationFees: 0,
      defaultPassword: null,
      accountLimit: null,
      depositAmounts: [500, 1000, 2000, 5000, 10000],
      selected: null,
      isSubmitting: false
    };
  },
  mounted() {
    this.getParams()
  },
  methods: {
    getParams(){
      API.get(this.url)
          .then(response => {
            if (response.status === 200){
              const parameter = response.data["hydra:member"][0]
              this.id = parameter.id
              this.registrationFees = parameter.registrationFees
              this.selected = parameter.depositAmount
              this.defaultPassword = parameter.defaultPassword
              this.accountLimit = parameter.accountLimit
            }
          })
          .catch(() => {})
    },
    updateParams(){
      this.isSubmitting = true
      API.patch(this.url + '/' + this.id, {
        registrationFees: this.registrationFees,
        depositAmount: this.selected,
        defaultPassword: this.defaultPassword,
        accountLimit: this.accountLimit
      })
          .then(() => {

            window.location.reload()
          })
          .catch(() => {
            this.isSubmitting = false
          })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader 
      :show-add-button="false" 
      :title="'Paramètre de l\'application'"
      :show-search="false" 
    />
    <section>
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="updateParams">

            <div class="">
              <div class="">
                <div>
                  <label for="firstName" class="form-label">Frais d'inscription</label>
                  <input type="number" class="form-control" id="firstName" v-model="registrationFees" placeholder="Frais d'inscrition">
                </div>
              </div>
              <div class="">
                <div>
                  <label for="firstName" class="form-label">Mot de passe par défaut</label>
                  <input type="text" class="form-control" id="firstName" v-model="defaultPassword" placeholder="Mot de passe par défaut">
                </div>
              </div>
              <div class="">
                <div>
                  <label for="firstName" class="form-label">Limite de compote</label>
                  <input type="number" class="form-control" id="firstName" v-model="accountLimit" placeholder="Limite de compte">
                </div>
              </div>
              <!--end col-->
<!--              <div class="">-->
<!--                <div>-->
<!--                  <label for="depositAmount" class="form-label">Epargnes</label>-->
<!--                  <select class="form-control" v-model="selected" id="depositAmount" multiple>-->
<!--                    <option v-for="(amount, index) in depositAmounts" :key="index" :value="amount">{{ amount }}</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
              <div class="col-lg-12 mt-2">
                <div class="hstack gap-2 justify-content-end">
                  <button type="submit" :disabled="isSubmitting" class="btn btn-primary">Enregistrer</button>
                </div>
              </div><!--end col-->
            </div>
          </form>
        </div>
      </div>
    </section>
  </Layout>
</template>